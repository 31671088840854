<template>
    <div>
        
        <a @click="$router.go(-1)" class="btn btn-neutral btn-icon-only rounded-circle position-absolute left-4 top-4 d-none d-lg-inline-flex" data-toggle="tooltip" data-placement="right" title="" data-original-title="Go back">
            <span class="btn-inner--icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
                </svg>
            </span>
        </a>
        <section>
            <div class="container d-flex flex-column">
                
                <div class="row mx-n2 justify-content-center align-items-center justify-content-center min-vh-100">
                    <div style="position:absolute;top:21%;">
                        <div class="d-flex align-items-center mb-4"><div class="d-flex">
                            <div class="pr-3">
                               
                                </div>
                                <div class="">
                                    <h3 class="h4 mb-0">What's your level of interest?</h3>
                                </div></div></div>
                    </div>
                    <div class="col-md-3 col-sm-6 px-2">
                        <div class="card hover-translate-y-n6 hover-shadow-lg" @click="$router.push({name: 'ChatSchedule'})" style="cursor:pointer;">
                            <div class="card-body text-center">
                                <div class="avatar-parent-child">
                                    <i class="uil uil-comment-lines" style="font-size:6rem"></i>
                                </div>
                                <a href="#" class="d-block h6 mt-4 mb-0">Schedule an Appointment</a> 
                                <span class="d-block text-sm text-muted mb-3">Learn more by speaking with a respresentative</span>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-sm-6 px-2">
                       
                    </div>
                    
                    <div class="col-md-3 col-sm-6 px-2">
                        <div class="card hover-translate-y-n6 hover-shadow-lg" @click="$router.push({name: 'A'})" style="cursor:pointer;">
                            <div class="card-body text-center">
                                <div class="avatar-parent-child">
                                    <i class="uil uil-signout" style="font-size:6rem"></i>
                                </div>
                                <a href="#" class="d-block h6 mt-4 mb-0">Get Started</a> 
                                <span class="d-block text-sm text-muted mb-3">Begin our hassle free application process</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: '',
        }
    },
}
</script>