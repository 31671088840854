<template>
<div>
    <section class="slice py-6 pt-lg-7 pb-lg-8" style="background-image: url('/img/office.jfif');background-size:cover;background-position:center center;">
        <span class="mask bg-gradient-dark opacity-4"></span>
        <div class="container d-flex align-items-center text-center text-lg-left">
            <div class="col px-0">
                <div class="row row-grid align-items-center">
                    <div class="col-lg-6 mt-6">
                        <h1 class="h1 text-white text-center text-lg-left my-4">Have a question <strong>in mind?</strong></h1>
                        <p class="lead text-white text-center text-lg-left opacity-8">
                            Need to get in touch with us? Here's how.
                        </p>
                    <div class="mt-5 text-center text-lg-left">
                        <button @click="focusForm" data-scroll-to="" class="btn btn-white btn-lg btn-icon">
                            <span class="btn-inner--icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                </svg> 
                            </span>
                            <span class="btn-inner--text">Write a message</span>
                        </button>
                    </div>
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-left mt-5">
                        <div class="col-auto text-sm text-white pl-0 pr-4"></div>
                        <div class="client-group col">
                            <div class="row">
                               
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-container shape-line shape-position-bottom">
        <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space="preserve" class=""><polygon points="2560 0 2560 100 0 100"></polygon>
        </svg>
    </div>

    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-center mt-4 ">
             
                
                
                <div class="col-md-5 contactForm" ><div class=card>
                        <div class="card-body p-5 text-center">
                            <div class=pb-4>
                                <div class="icon text-warning icon-sm mx-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                    </svg>
                                </div>
                            </div>
                            <h5>(800) 256-5307</h5>
                            <p class="mt-2 mb-0">Our support team is here for you.</p>
                        </div>
                    </div>
                </div>
                <div class=col-md-5>
                    <div class=card>
                        <div class="card-body p-5 text-center">
                            <div class=pb-4>
                                <div class="icon text-warning icon-sm mx-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </div>
                            </div>
                            <h5 style="font-size:1.15rem;"><a href=mailto:questions@visionaryinsurance.life>Questions@visionaryinsurance.life</a></h5>
                            <p class="mt-2 mb-0">You'll get a reply in 4 to 12 hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg" id="sct-form-contact">
        <div class="container position-relative zindex-100">
            <div v-if="!submitted" class="row justify-content-center mb-5" id="contactForm">
                <div class="col-lg-6 text-center">
                    <h3>Contact us</h3>
                    <p class="lh-190">
                        If there's something we can help you with, just let us know. We'll be more than happy to offer you our help
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                
                <div v-if="!submitted" class="col-lg-6">
                    <div id="form-contact">
                        <div class="form-group">
                            <input v-model="form.name" class="form-control form-control-lg" type="text" placeholder="Your name" refs="contactForm" required="">
                        </div>
                        <div class="form-group">
                            <input v-model="form.email" @blur="validateEmail" class="form-control form-control-lg" type="email" placeholder="email@example.com" required="">
                        </div>
                        <div class="form-group">
                            <input v-model="form.phone" @input="acceptNumber" class="form-control form-control-lg" type="text" placeholder="(745) 234-567" required="">
                        </div>
                        <div class="form-group">
                            <textarea v-model="form.message" class="form-control form-control-lg" data-toggle="autosize" placeholder="Ask us a question or leave us a comment ..." rows="3" required="" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 106px;">
                            </textarea>
                        </div>
                        <div class="text-center">
                            <button type="reset" class="btn-reset d-none"></button> 
                            <button @click="submitForm()" type="submit" class="btn btn-block btn-lg btn-primary mt-4">
                                <span v-if="loading == false">Send your message</span>
                                <span v-else>
                                    <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else class="col-6 py-5 text-center">
                    <h6 class="display-1 mb-3 font-weight-600 text-warning">Success!</h6>
                    <p class="lead text-lg mb-5">
                        Thank you for contacting us, a representitive will reach out to you shortly.</p>
                        <a href="/" class="btn btn-dark btn-icon hover-translate-y-n3 router-link-active">
                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            </span>
                            <span class="btn-inner--text">Return home</span>
                        </a>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            loading: false,
            submitted: false,
            emailValid: '',

            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                is_direct: 1,
            },
        }
    },
    methods: {
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submitForm() {
            if(this.form.name != '' && this.form.email != '' && this.emailValid == true && this.form.phone != '' && this.form.message != '') {
                this.loading = true;
                axios.post('/api/submitcontact', this.form).then(resp => {
                    console.log(resp);
                    this.submitted = true;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            } else if(this.form.name == '') {
                this.$toasted.error('Include Name');
            } else if(this.form.email == '') {
                this.$toasted.error('Include Email');
            } else if(this.emailValid == false) {
                this.$toasted.error('Invalid Email');
            }  else if(this.form.phone == '') {
                this.$toasted.error('Include Phone');
            } else if(this.form.message == '') {
                this.$toasted.error('Include Message');
            }
            
        },
        focusForm() {
            
            const el = this.$el.getElementsByClassName('contactForm')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        },
    }
}
</script>