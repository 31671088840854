<template>
<div>
    <section class="vh-100 d-flex align-items-center">
        <div class="bg-primary position-absolute h-100 top-0 right-0 zindex-110 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end rounded-bottom-left" data-bg-size="cover" data-bg-position="center">
            <img src="/img/canyon.jpg" alt="Image" class="img-as-bg rounded-bottom-left">
            <div class="container-fluid" style="position:absolute;top:25%;">
                <div class="row justify-content-center align-items-center">
                <div class="col-lg-8">
                    <forest-quoter></forest-quoter>
                </div>
            </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-7 col-lg-7 col-xl-7 mx-auto ml-lg-0">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-11 col-xl-7">
                            <h6 class="display-4 mb-5 font-weight-600 text-warning">How to Apply</h6>
                            <div class="card shadow-none">
                                <div class="p-3 d-flex">
                                    <div>
                                        <div class="icon icon-shape rounded-circle bg-warning text-white mr-4">
                                           <span style="font-size:1.25rem;">1</span>
                                        </div>
                                        </div>
                                        <div>
                                        <span class="h6">Obtain a quote</span>
                                        <p class="text-sm text-muted mb-0">
                                            You are in Control.  Based on your age, amount of coverage and Term Length you are eligible to apply for coverage.
                                        </p>
                                        </div>
                                </div>
                            </div>
                            <div class="card shadow-none">
                                <div class="p-3 d-flex">
                                    <div>
                                        <div class="icon icon-shape rounded-circle bg-success text-white mr-4">
                                            <span style="font-size:1.25rem;">2</span>
                                        </div>
                                        </div>
                                        <div>
                                        <span class="h6">Once you have your quote</span>
                                        <p class="text-sm text-muted mb-0">
                                            You can either:<br> A) Request to begin the application or B) Discuss the coverage with one of our staff members
                                        </p>
                                        </div>
                                </div>
                            </div>
                            <div class="card shadow-none">
                                <div class="p-3 d-flex">
                                    <div>
                                        <div class="icon icon-shape rounded-circle bg-primary text-white mr-4">
                                            <span style="font-size:1.25rem;">3</span>
                                        </div>
                                        </div>
                                        <div>
                                        <span class="h6">Recieve Policy</span>
                                        <p class="text-sm text-muted mb-0">
                                             Within a few days we will have a decision regarding your coverage. At that time you are free to make changes if necessary.  Your policy will be mailed to you directly and you will then have a 30 day Free Look to make any changes again.
                                        </p>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';
import forestQuoter from './form/Quoter.vue'

export default {
  name: 'Home',
  components: {
    forestQuoter,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>
<style scoped>
.nav-link {
    color:black;
}
</style>