<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                
                                
                                <h3 class="h5 mb-4">How can we get in touch?</h3>
                                    <div class="row">
                                        
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">First name</label> 
                                                    
                                                    <input v-model="$store.state.directForm.first_name" class="form-control text-muted" type="text" placeholder="First name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Last name</label> 
                                                    <input v-model="$store.state.directForm.last_name" class="form-control text-muted" type="text" placeholder="Last name">
                                                </div>
                                            </div>
                                    </div>
                                
                                
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="$store.state.directForm.email" @input="validateEmail"  class="form-control text-muted" type="text" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="$store.state.directForm.phone" @input="acceptNumber" class="form-control text-muted" type="text" placeholder="Phone">
                                            </div>
                                        </div>
                                    </div>
                               
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group" style="height:2px;">
                                    
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                                <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            form: '',
            buttonYes: false,
            buttonNo: false,
            emailValid: false,
        }
    },
    created() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
    },
    methods: {
        submit() {
            if(this.$store.state.directForm.first_name.length > 2 && this.$store.state.directForm.last_name.length > 3 && this.emailValid && this.$store.state.directForm.phone.length > 6) {
                
                this.$router.push({name: 'Schedule'})
            } else if (this.$store.state.directForm.first_name.length < 2){
                this.$toasted.error('Invalid First Name');
            }
            
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        acceptNumber() {
            var x = this.$store.state.directForm.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.$store.state.directForm.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
 .outlineTicketSuccess {
    border-color: #5cc9a7;
}
</style>