<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Ticket Accept</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-8" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        <div class="col-md-12 mb-1" style="display: flex; justify-content: center;">
                            <avatar :username="$store.getters.returnUserName" :size="90" class="rounded-circle"> </avatar>
                        </div>
                        <div class="col-md-12 text-center">
                            <span class="d-block h6 mt-4 mb-0">{{$store.getters.returnUserName}}</span>
                        </div>
                        <div class="col-md-12">
                            <div class="card mt-5">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="media align-items-center">
                                               
                                                <div class="media-body text-center">
                                                    <h5 class="mb-0">{{$moment($parent.ticket.hour.hour + 'z').local().format('h:mm a')}}</h5>
                                                    <h5 class="mb-0">{{$moment($parent.ticket.hour.hour + 'z').local().format('dddd MMM, D YYYY')}}</h5>
                                                   
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6"></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                      
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="acceptSlot()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span v-if="!loading">Accept Time</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "enroller-accept2",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            form: {
                name: '',
            },
        }
    },
   
    methods: {
        acceptSlot() {
            this.loading = true;
            
            this.form.hour_id = this.$parent.ticket.hour.id;
            this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
            this.form.ticket_id = this.$parent.ticket.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/scheduleslot', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticket.enroller_id = resp.data.e;
                this.$parent.ticket.status = 'pending enrollment';
            }).then(resp2 => {
                this.loading = false;
                
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
                this.$parent.$toasted.success("Scheduled");
            }).catch(err => {
                this.loading = false;
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
            });

        }
    }
    
}
</script>