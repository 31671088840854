<template>
<div>
    
    <section class="slice py-8 bg-dark bg-cover bg-size--cover" style="background-image:url('/img/hand-san.jpg')">
        <span class="mask bg-gradient-dark opacity-4"></span>
        <div class="container d-flex align-items-center text-center text-lg-left pt-5 pb-5 pt-lg-6">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center mt-4">
                    <div class="col-lg-8 text-center text-lg-left">
                        <h1 class="text-white">Getting Started</h1>
                        <h2 class="text-white mb-4">How do I enroll in life insurance?</h2>
                        <p class="lead text-white opacity-9" style="font-size:1.25rem;">
                           
                        </p>
                        <div class="mt-5">
                            <router-link :to="{name: 'HowToApply'}" class="btn btn-warning btn-lg btn-icon">
                                <span class="btn-inner--text">Get started</span><span class="btn-inner--icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape-container shape-line shape-position-bottom">
            <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space="preserve" class=""><polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <span class="badge badge-primary badge-pill">Overview</span>
            <div class="row mt-4">
                <div class="col-lg-6 pr-lg-5">
                    <div class="sticky-element">
                        <p class="h5 lh-180 mb-3">
                       Qualifying for Term Life Insurance is an easy, reliable way to help your family manage financial burdens such as funeral costs, medical bills and outstanding debts by paying benefits from $50,000 to $400,000 in life insurance benefits.
                    </p>
                    </div>
                    
                    
                </div>
                <div class="col-lg-6">
                    <p class="lead text-dark lh-180 mb-3">
                       Coverage begins on the date of Issue or a date that best fits you. With a fast application process and fast decisions, there are no barriers between you and the protection you need. 
                    </p>
                    <p class="lead text-dark lh-180">
                        This policy is available in all states except New York.  Citizens between 18 and 80 years of age. NO MEDICAL EXAM or lengthy health questionnaires are required. Once approved, guaranteed coverage continues to age 95. Premiums are payable till age 95 and are guaranteed to never go up during the initial term period. Death Benefit remains Level as long as premiums are paid.  
                    </p>
                    <p class="lead text-dark lh-180">
                        Included at NO ADDITIONAL Cost, coverage for a Long Term Care Event, as well as coverage for surviving a Heart Attack, Stroke, or Cancer.  Money sent to you Tax Free to
                        help with expenses while recovering.  
                    </p>
                    <p class="lead text-dark lh-180">
                        
                        Coverage begins on the date of Issue or a date that best fits you.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-12">
                    <forest-quoter></forest-quoter>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
<script>
import forestQuoter from './form/Quoter.vue'
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    forestQuoter
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>