<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                
                                
                                <h5 class="mt-4 mb-4">Schedule a chat with a represenative</h5>
                                
                                    <div class="row">
                                        <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">First name</label> 
                                                    
                                                    <input v-model="$store.state.directForm.first_name" class="form-control text-muted" type="text" placeholder="First name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Last name</label> 
                                                    <input v-model="$store.state.directForm.last_name" class="form-control text-muted" type="text" placeholder="Last name">
                                                </div>
                                            </div>
                                      
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="$store.state.directForm.email" @input="validateEmail"  class="form-control text-muted" type="text" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="$store.state.directForm.phone" @input="acceptNumber" class="form-control text-muted" type="text" placeholder="Phone">
                                            </div>
                                        </div>
                                
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Select Timezone </label> 
                                                <select @change="selectTimezone" class="form-control custom-select-lg">
                                                    <option selected>Select from menu</option>
                                                    <option value="America/Los_Angeles">Pacific Time - {{$moment(new Date()).tz('America/Los_Angeles').format('h:mm a')}}</option>
                                                    <option value="America/Phoenix">Arizona Time - {{$moment(new Date()).tz('America/Phoenix').format('h:mm a')}}</option>
                                                    <option value="America/Denver">Mountain Time - {{$moment(new Date()).tz('America/Denver').format('h:mm a')}}</option>
                                                    <option value="America/Chicago">Central Time - {{$moment(new Date()).tz('America/Chicago').format('h:mm a')}}</option>
                                                    <option value="America/New_York">Eastern Time - {{$moment(new Date()).tz('America/New_York').format('h:mm a')}}</option>
                                                                                           
                                                </select>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div v-if="timeZone" class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Schedule Day </label> 
                                                <select @change="selectDateChange" class="form-control custom-select-lg">
                                                    <option selected>Select Date</option>
                                                    <option v-for="(i, index) in sSlots" :key="i.id" :value="index">{{i.day}}</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Schedule Time </label> 
                                                <select @change="selectedHour" class="form-control custom-select-lg">
                                                    <option selected>Select time</option>
                                                    <option v-for="i in sHours" :key="i.id" :disabled="shouldDisableSlot(i)" :value="i.id">{{printDateTimezone(i.hour)}}</option>
                                                                                          
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                               
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group" style="height:2px;">
                                    
                                    
                                </div>
                                <div v-if="!sLoading" class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submitTicket()" >Continue</button>
                                </div>
                                <div v-else>
                                    <button type="button" class="btn btn-block btn-primary hover-continue">
                                        <span>
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </span>
                                    </button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                            <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';
import formHeader from "./Header.vue";
import moment from 'moment';

export default {
    components: {
        formHeader, moment,
    },
    data() {
        return {
            sLoading: false,
            form: '',
            buttonYes: false,
            buttonNo: false,
            timeZone: '',
            sSlots: '',
            sHours: '',
            form: "",
            emailValid: '',
        }
    },
    created() {
        this.fetchScheduler();
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
            console.log('email looks alright');  
            this.emailValid = true;
        } else {
            console.log('Please enter a valid email address');
            this.emailValid = false;
        }
    },
    methods: {
        submitChat() {

        },
        submitTicket() {
            this.$store.state.directForm.policy_coverage = this.$store.state.dPolicy.amount;
            this.$store.state.directForm.policy_term = this.$store.state.dPolicy.term;
            this.$store.state.directForm.policy_age = this.$store.state.dPolicy.age;
            this.$store.state.directForm.policy_ss = this.$store.state.dPolicy.ss;
            this.$store.state.directForm.policy_monthly = this.$store.state.dPolicy.quote * 100;
            this.$store.state.directForm.gender = this.$store.state.dPolicy.gender;
            this.$store.state.directForm.state = this.$store.state.dPolicy.state;
            this.$store.state.directForm.dob = this.$store.state.dPolicy.dob;
           /// this.$store.state.directForm.ticket_type = 'chat';
            
            //this.$store.state.dPolicy.ss == 'ns' ? this.$store.state.directForm.tobacco = "No" : this.$store.state.directForm.tobacco = "Yes"
            
            if(this.$store.state.directForm.first_name != '' && this.$store.state.directForm.schedule_hourid != '' && this.$store.state.directForm.last_name != '' && this.$store.state.directForm.phone != '' && this.$store.state.directForm.email != '' && this.emailValid == true ) {
                console.log('inside direct form')
                axios.post('/api/createdirectchat', this.$store.state.directForm).then(resp => {
                    console.log(resp.data)
                    this.sLoading = false;
                    this.$store.dispatch('clearPolicy');
                    this.$router.push({name: 'ChatDone'})
                }).catch(err => {
                    this.sLoading = false
                    this.$toasted.error("Please try again");
                });
            } else if(this.$store.state.directForm.first_name == '') {
                console.log('else here')
                this.$toasted.error("Include First Name")

            } else if(this.$store.state.directForm.last_name == '') {
                console.log('else here')
                this.$toasted.error("Include First Name")

            } else if(this.$store.state.directForm.email == '') {
                console.log('else here')
                this.$toasted.error("Include First Name")

            } else if(this.$store.state.directForm.phone == '') {
                console.log('else here')
                this.$toasted.error("Include Phone")

            } else if(this.emailValid == false) {
                console.log('else here')
                this.$toasted.error("Include Valid Email")

            } else if(this.$store.state.directForm.schedule_hourid == '') {
                console.log('else here')
                this.$toasted.error("Select scheduled time")

            } 

            /* if(this.$store.state.directForm.schedule_hourid != '') {
                console.log('init through')
                this.sLoading = true;
                axios.post('/api/createdirectchat', this.$store.state.directForm).then(resp => {
                    console.log(resp.data)
                    this.sLoading = false;
                    this.$router.push({name: 'Done'})
                }).catch(err => {
                    this.sLoading = false
                    this.$toasted.error("Please try again");
                });
            } else if (this.$store.state.directForm.schedule_hourid == '') {
                this.$toaster.error("Select scheduled hour")
            }  */
            
        },
        submitElse() {
            if(this.$store.state.directForm.schedule_hourid != '' && this.$store.state.directForm.first_name != '') {
                console.log('ello')
            } else if(this.$store.state.directForm.schedule_hourid == '') {
                console.log('missing hour')
            } else if(this.$store.state.directForm.first_name == '') {
                console.log('missing first name')
            }
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
            }
        },
        fetchScheduler() {
            //axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/direct/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        acceptNumber() {
            var x = this.$store.state.directForm.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.$store.state.directForm.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.$store.state.directForm.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.$store.state.directForm.schedule_hourid = event.target.value;
            }
            
        },
        printDateTimezone(i) {
            return moment.utc(i).tz(this.timeZone).format('hh:mm a');
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>