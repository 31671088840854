<template>
<div>
    <section class="slice pt-9 pb-7 bg-light">
        <img src="/img/hand-showing.jfif" alt="Image" class="img-as-bg blend-mode--multiply">
        <span class="mask bg-gradient-dark opacity-4"></span>
        <div data-offset-top="#navbar-main">
            <div class="container d-flex align-items-center text-center text-lg-left">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="h1 text-white mt-6 mb-2">Benefits</h2>
                            <h3 class="font-weight-light text-white opacity-8">Term Life Insurance</h3>
                            <h3 class="font-weight-light text-white opacity-8">Individual Policy</h3>
                            <div class="my-3">
                                
                            </div>
                            <div class="row mt-5">
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Relaxed Acceptance</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        This plan was created with individuals with Type 1 or Type 2 Diabetics.  Available to US Citizens between the ages of 18 to 80.   No need for an exam.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">No Medical Exams</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        There are no medical exams or lengthy health questionnaires required to be accepted for coverage. The state of your health after initial approval cannot influence the future for this policy during the life of the policy..
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Term Coverage is Guaranteed</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <strong>Guaranteed lifelong coverage with premiums payable until age 95.</strong>   The term is convertible to any permanent policy offered by the carrier. As a contractual right, the owner can convert the base face amount to a new permanent life insurance certificate during the conversion period without having to provide evidence of insurability. Partial conversions are allowed. Riders cannot be converted (subject to the terms and conditions of the contract). Conversion can be to any permanent product made available and underwritten by The Independent Order of Foresters. The new certificate, including premiums, will be based on the insured’s age at time of issue.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Guaranteed Rates</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Premiums are fixed for the life of the term and coverage is guaranteed to age 95. During the initial term period, regardless of your future health, premiums can not increase and coverage can not be reduced. After the initial term period, coverage can renew at annual renewable rates to age 95. 
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Charity Benefit Provision</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                      
                        Our Term products come with a built-in Charity Benefit provision for no additional premium. In addition to the death benefit, when a claim is paid to the beneficiaries, we will donate 1% (up to a maximum of $100,000) of the face value of your certificate, to an eligible designated registered charitable organization of your choosing
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Free Policyholder Benefits</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                      
                        Free Benefits that support you and your family.  No other Insurance company offers these additional benefits for becoming a Policyholder.  
                        <a href="/pdf/Policyholder-Benefits.pdf" class="text-primary" style="cursor:pointer;">Learn More</a>
                    </p>
                    
                </div>
            </div>
            
           
       
            <div class="row justify-content-between">
            <div class="col-lg-3 text-lg-right">
                <h4 class="mb-4"></h4>
            </div>
            <div class="col-lg-8">
                <forest-quoter></forest-quoter>
            </div>
            </div>
    </div>
</section>
</div>
</template>
<script>
import forestQuoter from './form/Quoter.vue'
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    forestQuoter,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      showBenefits() {
          alert('Show Benefits');
      },    
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>