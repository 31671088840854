<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-12">

                    
                    <div class="row mt-6">
                    <div class="col-6" v-for="(i, index) in userList" :key="i.id">
                                            <div class="card card-fluid shadow-lg">
                                                
                                            <div class="card-body">
                                                <div class="row pt-3" style="">
                                                    <div class="col-8 text-start pl-4">
                                                        <span class="h6"><i class="uil uil-user"></i> {{i.name}}</span><br>
                                                        <span class="h6 mb-0"><i class="uil uil-envelope"></i> {{i.email}}</span>
                                                    </div>
                                                    
                                                    <div class="col-4 text-center">
                                                        <span class="h6 mb-0"><i class="uil uil-phone"></i> {{i.phone}}</span>
                                                    </div>
                                                </div>
                                                <div class="row pt-4 justify-content-center">
                                                    <div class="col-10">
                                                        <p class="text mb-0" style="text-transform: capitalize">{{i.role}}</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="card-footer py-2">
                                                <div class="row align-items-center">
                                                    <div class="col-6">
                                                        <button type="button" class="btn btn-sm px-0 font-weight-bold btn-link text-primary btn-icon"></button>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        
                                                        <button type="button" @click="showUserModify(index)" class="btn btn-xs btn-outline-danger rounded-pill">Manage</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                    </div>
                    <div v-observe-visibility="scrollBottom"></div>
                        <!-- <div class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards align-items-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width:240px">Name</th>
                                                <th scope="col">Agents</th>
                                                
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr v-for="i in userList" :key="i.id">
                                                <th scope="row">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child">
                                                                <avatar username="cool name" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i}}</a> 
                                                            <small class="d-block font-weight-bold">i.user.email</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm ml-3">i.agent.length</span> 
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    
                                                </td>
                                                <td class="text-right">
                                                    <div class="actions ml-6">
                                                        link here
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                            

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div> -->
                    </div>
                    
                    <div style="position:absolute;top:0px;margin-top:0px;right:10px;">
                        <div class="col-auto">
                            <button @click="showUserCreate()" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-plus"></i>
                                </span> 
                                <span class="btn-inner--text">Add User</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            
        </div>
        </div>

        <recruitor-create 
            ref="recCreate"
        />
        <user-modify 
            ref="userModify"
            
        />
        <user-create 
            ref="userCreate"
            
        />
            
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
import userModify from '../comps/UserModify.vue'
import userCreate from '../comps/UserCreate.vue'
import RecruitorCreate from '../comps/RecruitorCreate.vue';
export default {
    components: {
        Avatar,
        userModify, userCreate,
        RecruitorCreate,
    },
    data() {
        return {
            loading: false,
            userList: [],
            page: 1,
            last_page: 1,
            routeInitial: false,
            userModValues: " ",
            userModValues2: {
                name: '',
                email: '',
                password: '',
                agent: false,
                recruiter: false,
                enroller: false,
                recruiter_id: '',
            },
            userModValueRoles: {
                agent: '',
                recruiter: '',
                enroller: '',
                changePassword: false,
            },
            confirm: {
                agent: false,
                recruiter: false,
                enroller: false,
                admin: false,
                agentAdd: false,
                pass: '',
                passC: '',
                pc: 0,
            },
            modIndex: '',
            ucRoles: {
                agent: false,
                recruiter: false,
                enroller: false,
            },
            pLoad: false,
        }
    },
    created() {
        this.fetchUsers();
        
    },
   
    methods: {
        fetchUsers() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`/api/fetchusers?page=${this.page}`).then(resp => {
                console.log(resp.data.t);
                this.userList.push(...resp.data.u.data);
                this.last_page = resp.data.u.last_page;
            }).then(resp2 => {
                this.loading = false;
            }).catch(err => { 
                if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                this.loading = false });
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }

            console.log('Bottom');
            this.page++;
            this.fetchUsers();
        },
        showCreate() {
            $(this.$refs.recCreate.$el).modal('show');
        },
        showUserModify(i) {
            this.modIndex = i;
            this.userModValues  = this.userList[i];
            this.userModValueRoles.admin = this.userList[i].role.toLowerCase().includes('admin');
            this.userModValueRoles.agent = this.userList[i].role.toLowerCase().includes('agent');
            this.userModValueRoles.recruiter = this.userList[i].role.toLowerCase().includes('recruiter');
            this.userModValueRoles.enroller = this.userList[i].role.toLowerCase().includes('enroller');
            this.userModValueRoles.changePassword = false;
            this.confirm.agent = false; this.confirm.recruiter = false; this.confirm.enroller = false;this.confirm.admin = false; this.confirm.agentAdd = false;this.confirm.pass = '';this.confirm.passC='';this.confirm.pc = 0;
            this.pLoad = false;
            $(this.$refs.userModify.$el).modal('show');
        },
        showUserCreate() {
            this.userModValues2.name = '';this.userModValues2.email = '';this.userModValues2.password = '';this.userModValues2.recruiter_id = '';this.userModValues2.agent = false;this.userModValues2.enroller = false;this.userModValues2.recruiter = false;
            this.ucRoles.agent = false;this.ucRoles.recruiter=false;this.ucRoles.enroller=false;
            this.confirm.pass = '';this.confirm.passC='';this.confirm.pc = 0;
            $(this.$refs.userCreate.$el).modal('show');
        },
        
    },
    
}
</script>