<template>
<div>
    
    <section class="pt-10 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Strong Foundation Application</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div v-if="ticket != 'filled' && showForm" class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                           
                            <div class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Guaranteed Issue Term Life Insurance</h5>
                                                     <p class="text-muted h5 lh-150 text-sm mb-0">Name:  {{ticket.first_name + ' ' + ticket.last_name}}</p>
                                                    <p class="text-muted h5 lh-150 text-sm mb-0">Age: {{ticket.policy_age}}, <span style="text-transform: uppercase;">{{ticket.policy_ss}}</span> | Term: {{ticket.policy_term + " Years"}} </p>
                                                    <p class="text-muted h5 lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(ticket.policy_coverage * 1000)}} </p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6">{{formatCurrency(ticket.policy_monthly)}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mt-5">
                                
                                <h5 class="mb-3">Contact information</h5>
                               
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <p class="form-control" type="text">{{ ticket.first_name }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <p class="form-control" type="text">{{ ticket.last_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Date of Birth</label> 
                                                <p class="form-control" type="text">{{ ticket.dob }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Gender</label> 
                                                <p class="form-control" type="text">{{ ticket.gender }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <p class="form-control" type="text">{{ ticket.email }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <p class="form-control" type="text">{{ ticket.phone }}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div> -->
                            <hr>
                            
                            
                            <div>
                                <h5 class="mb-3">Doctor information</h5>
                                
                                
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Date Last Consulted with your Doctor</label> 
                                                <input v-model="form.doctor_date" class="form-control" type="date">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">
                                                    Name of Doctor or Medical Facility
                                                    </label> 
                                                <input v-model="form.doctor_name" class="form-control" type="text" placeholder="Enter name or medical facility">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Address of Doctor of Medical Facility</label> 
                                                        <input v-model="form.doctor_address" class="form-control" type="text" placeholder="Enter address">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Number</label> 
                                                        <input v-model="form.doctor_addressnum"  class="form-control" type="text" placeholder="Office #">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">
                                                Phone Number of Doctor of Medical Facility
                                                    </label> 
                                                <input v-model="form.doctor_phone" @input="acceptNumber" class="form-control" type="text" placeholder="(866) 847-4598">
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        
                                        
                                    </div>
                                    
                                    <div class="mt-3">
                                    
                                        
                                    </div>
                                    <h5 class="mb-3">Policy information</h5>
                                
                                
                                    <div class="row">
                                       
                                        
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">List of Beneficiaries and Relationship to you</label> 
                                               
                                                <textarea v-model="form.beneficiaries" class="form-control" placeholder="List of beneficiaries"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12 llpshow">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you have a Life Insurance Policy Currently Active?</label> 
                                                <select @change="isActive" class="form-control custom-select-lg">
                                                    <option value="" selected>Select option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="form.policy_isactive == 'Yes'" class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">If Yes, Name of the Company </label> 
                                                <input v-model="form.policy_company" class="form-control" type="text" placeholder="Name of company">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Amount of Life Insurance Currently Active? </label>
                                                   <input v-model="form.policy_amount" class="form-control" type="text" placeholder="Amount">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Year Life Insurance went into Force? </label> 
                                                <input v-model="form.policy_year" class="form-control" type="text" placeholder="Year policy went into force">
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                    
                                    <div class="mt-3">
                                    
                                        
                                    </div>
                                    <h5 class="mb-3">Bank information</h5>
                                
                                
                                    <div class="row">
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">To Provide Coverage immediately please provide the Name of your Bank: Note: This information can be collected at time of application. </label> 
                                                <input v-model="form.bank_name" class="form-control" type="text" placeholder="Bank name">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Routing Number: </label> 
                                                <input v-model="form.bank_routing" class="form-control" type="text" placeholder="Routing number">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Account Number: </label> 
                                                <input v-model="form.bank_account" class="form-control" type="text" placeholder="Account number">
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                    <div class="mt-3">
                                    
                                        
                                    </div>
                                    
                                    
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="submitApp()" type="button" class="btn btn-success">Submit Information</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                           
                            <div class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body text-center">
                                                    <h5 class="mb-0">Already Filled!</h5>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>  
</template>
<script>
import axios from 'axios';

export default {
    
    components: {
        
    },
    data() {
        return {
            showForm: false,
            ticket: '',
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
            form: {
                ticket_id: '',
                doctor_date: '',
                doctor_name: '',
                doctor_address: '',
                doctor_addressnum: '',
                doctor_phone: '',
                beneficiaries: '',
                policy_isactive: '',
                policy_company: '',
                policy_amount: '',
                policy_year: '',
                bank_name: '',
                bank_routing: '',
                bank_account: '',
            },
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            
        }
    },
    created() {
        window.scrollTo(0,0);
       this.fetchForestAdd();
    },
    mounted() {
        this.form.dob = this.$store.state.policy.dob;
        this.form.gender = this.$store.state.policy.gender;
        this.form.state = this.$store.state.policy.state;

        this.form.policy_age = this.$store.state.policy.age;
        this.form.policy_coverage = this.$store.state.policy.amount;
        this.form.policy_monthly = this.$store.state.policy.quote
        this.form.policy_start = this.$store.state.policy.startdate;

        if(this.$store.state.shelf.findIndex(item => item.id === 2) != -1) {
            this.form.spouse_dob = this.$store.state.policySpouse.dob;
            this.form.spouse_gender = this.$store.state.policySpouse.gender;

            this.form.policyspouse_age = this.$store.state.policySpouse.age;
            this.form.policyspouse_coverage = this.$store.state.policySpouse.amount;
            this.form.policyspouse_monthly = this.$store.state.policySpouse.quote
            this.form.policyspouse_start = this.$store.state.policySpouse.startdate;
        }
        if(this.$store.state.rider1.amount) {
            this.form.policychild_coverage = this.$store.state.rider1.amount;
            this.form.policychild_monthly = this.$store.state.rider1.cost;
        }

        
        

        var dateToday = new Date(); 
       // console.log('01: ' + dateToday);
       // console.log('02: ' + this.$moment(dateToday).utc().format());
       // console.log('1: ' + new Date('7-28-2021 14:00:00'))
       // console.log('2: ' +new Date('7-28-2021 14:00:00').toUTCString());
       // console.log(new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4));
        
        flatpickr('#datepicker', {
            
            dateFormat: "m-d-Y",
            minDate: dateToday,
            "disable": [
                function(date) {
                return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
                }
            ],
            "locale": {
                "firstDayOfWeek": 1 // set start day of week to Monday
            }
        });
        flatpickr('#datepicker2', {
            
            dateFormat: "m-d-Y",
           
        }); 
        
    },
    methods: {
        fetchForestAdd() {
            axios.get(`/api/fetchforestadd/${this.$route.params.slug}`).then(resp => {
                console.log(resp.data.tk);
                this.ticket = resp.data.tk;
                this.showForm = true;
            });
        },
       
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        isActive(event) {
          if(event.target.value !== '') {
              this.form.policy_isactive = event.target.value;
              console.log(event.target.value)
          }
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.form.doctor_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.doctor_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            console.log(this.form.doctor_phone.length);
        },
        acceptNumber2() {
            var x = this.form.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;

              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
                  
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
                  
              } else {
                  this.isSS = false;
                  
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        mState(event) {
          if(event.target.value !== '') {
              this.form.medical = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.form.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.form.schedule_hourid = event.target.value;
            }
            
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDateTimezone(i) {
            return moment.utc(i).tz(this.timeZone).format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        submitApp() {
            console.log(this.form);
            this.form.ticket_id = this.ticket.id;
            if(this.form.doctor_date != '' && this.form.doctor_name != '' && this.form.policy_isactive) {
                this.submitLoading = true;
                axios.post('/api/postforestadd', this.form).then(resp => {
                    console.log(resp);
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$router.push({name: 'AdditionalDone'});
                }).catch(err => {
                    this.submitLoading = false;
                });
            } else if(this.form.doctor_date == '') {
                this.$toasted.error('Include Last Consulted with your Doctor ')
            } else if(this.form.doctor_name == '') {
                this.$toasted.error('Name of Doctor or Medical Facility')
            }
            else if(this.form.policy_isactive == '') {
                this.$toasted.error('Do you have a Life Insurance Policy Currently Active')
            }
        },
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.form.hours == 'Yes' && this.form.medical == "No" && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.agentexplainedllp == 'Yes' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.form.hours != 'Yes') {
                this.$toasted.error('Must be working');
            } else if(this.form.medical != 'No') {
                this.$toasted.error('Medical Status');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp != 'Yes') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
       
       
    }

}
</script>
<style scoped>
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>