<template>
<div>
    <router-link :to="{name: 'Home'}" class="btn btn-neutral btn-icon-only rounded-circle position-absolute left-4 top-4 d-none d-lg-inline-flex" data-toggle="tooltip" data-placement="right" title="" data-original-title="Go back"><span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg></span>
    </router-link>

    <section class="vh-100 d-flex align-items-center">
        <div class="position-absolute h-100 top-0 right-0 zindex-110 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end rounded-bottom-left" data-bg-size="cover" data-bg-position="center">
            <img src="/img/canyon.jpg" alt="Image" class="img-as-bg rounded-bottom-left">
        </div>
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-sm-7 col-lg-6 col-xl-6 mx-auto ml-lg-0">
                    <div class="row justify-content-center">
                        <div class="col-11 col-lg-10 col-xl-6 py-5">
                            <h6 class="display-4 mb-3 font-weight-600 text-warning">Congratulations!</h6>
                            <p class="lead text-lg mb-5">Thank you for scheduling a chat, A licensed professional from Visionary will reach out to you shortly.</p>
                            <router-link :to="{name: 'Home'}" class="btn btn-dark btn-icon hover-translate-y-n3">
                                <span class="btn-inner--icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                </span> 
                                <span class="btn-inner--text">Return home</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
<script>
export default {
    created() {
        window.scrollTo(0,0);
    }
}
</script>